<template>
  <div class="body">
    <!-- 轮播图 -->
    <swiper class="swiper" :options="swiperOption" ref="interSwiper">
      <swiper-slide class="swiper" :options="swiperOption" ref="interSwiper"><img
          src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/0101.jpg"
          alt=""></swiper-slide>
      <swiper-slide class="swiper"><img
          src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/0202.jpg"
          alt="" :options="swiperOption" ref="interSwiper"></swiper-slide>
      <swiper-slide class="swiper"><img
          src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/0303.jpg"
          alt="" :options="swiperOption" ref="interSwiper"></swiper-slide>
      <swiper-slide class="swiper"><img
          src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/0404.jpg"
          alt="" :options="swiperOption" ref="interSwiper"></swiper-slide>
      <!-- Optional controls -->
      <div class="swiper-pagination" slot="pagination"></div>
      <!-- <div class="swiper-button-prev" slot="button-prev" @click='swiperPrev'></div>
      <div class="swiper-button-next" slot="button-next" @click='swiperNext'></div> -->

    </swiper>
       <!-- 第二部分 广告类型-->
       <div class="case-two">
        <div class="part-two-top">
        <p class="part-two-top-p1">浙江新聚营销案例</p>
        <p class="part-two-top-p2">通过OPPO营销获取更多客户</p>
      </div>
      <ul class="case-two-tab">
          <li @click="iscaseTwo=0" :class="iscaseTwo==0?'case-active':''">教育</li>
          <li @click="iscaseTwo=1" :class="iscaseTwo==1?'case-active':''">口腔</li>
          <li @click="iscaseTwo=2" :class="iscaseTwo==2?'case-active':''">防水</li>
          <li @click="iscaseTwo=3" :class="iscaseTwo==3?'case-active':''">小说</li>
          <li @click="iscaseTwo=4" :class="iscaseTwo==4?'case-active':''">招商加盟</li>
          <li @click="iscaseTwo=5" :class="iscaseTwo==5?'case-active':''">案例</li>
    </ul>
        <ul class="case-two-cont" v-show="iscaseTwo==0">
          <li>
            <img class="case-two-cont-img" src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/1/case1.jpg" alt="">
            <div class="case-two-text">
              <p class="case-two-text-p1">钱塘教育OPPO营销平台推广案例：零基础轻松学理财</p>
            <p class="case-two-text-p2">通过深度解析财商教育行业目标人群，定向投放理财需求人群</p>
            <p class="case-two-text-p3">钱塘教育|浙江新聚</p>
            </div>
          </li>
          <li>
            <img class="case-two-cont-img" src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/1/case8.jpg" alt="">
            <div class="case-two-text">
              <p class="case-two-text-p1">沐思科技OPPO营销平台推广案例：职业占星师，5天体验营</p>
            <p class="case-two-text-p2">通过深度剖解析教育行业目标人群，定向投放占星师需求人群</p>
            <p class="case-two-text-p3">沐思科技|浙江新聚</p>
            </div>
          </li>
        </ul>
        <ul class="case-two-cont" v-show="iscaseTwo==1 ">
          <li>
            <img class="case-two-cont-img" src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/1/case4.jpg" alt="">
            <div class="case-two-text">
              <p class="case-two-text-p1">诺贝尔口腔OPPO营销平台推广案例：在线通过获取种植牙报价</p>
            <p class="case-two-text-p2">通过深度解析种植牙行业目标人群，定向投放种牙需求人群</p>
            <p class="case-two-text-p3">诺贝尔口腔|浙江新聚</p>
            </div>
          </li>
        </ul>
        <ul class="case-two-cont" v-show="iscaseTwo==2 ">
          <li>
            <img class="case-two-cont-img" src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/1/case2.jpg" alt="">
            <div class="case-two-text">
              <p class="case-two-text-p1">雨虹防水OPPO营销平台推广案例：免费上门勘察</p>
            <p class="case-two-text-p2">通过深度解析防水行业目标人群，定向投放需求人群</p>
            <p class="case-two-text-p3">雨虹防水|浙江新聚</p>
            </div>
          </li>
        </ul>
        <ul class="case-two-cont" v-show="iscaseTwo==3 ">
          <li>
            <img class="case-two-cont-img" src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/1/case3.jpg" alt="">
            <div class="case-two-text">
              <p class="case-two-text-p1">灵猫免费小说OPPO营销平台推广案例：精彩小说全本免费看</p>
            <p class="case-two-text-p2">通过深度解析小说行业目标人群，定向投放小说需求人群</p>
            <p class="case-two-text-p3">灵猫小说|浙江新聚</p>
            </div>
          </li>
        </ul>
        <ul class="case-two-cont" v-show="iscaseTwo==4 ">
          <li>
            <img class="case-two-cont-img" src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/1/case7.jpg" alt="">
            <div class="case-two-text">
              <p class="case-two-text-p1">欢乐番茄OPPO营销平台推广案例：“社区生鲜”风口项目，诚邀加盟</p>
            <p class="case-two-text-p2">通过深度剖解析招商加盟行业目标人群，定向投放加盟需求人群</p>
            <p class="case-two-text-p3">欢乐番茄|浙江新聚</p>
            </div>
          </li>
          <li>
            <img class="case-two-cont-img" src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/1/case9.jpg" alt="">
            <div class="case-two-text">
              <p class="case-two-text-p1">豪屋设计OPPO营销平台推广案例：免费获取装修报价，一站式装修服务</p>
            <p class="case-two-text-p2">通过深度剖解析家装行业目标人群，定向投放装修需求人群</p>
            <p class="case-two-text-p3">豪屋设计|浙江新聚</p>
            </div>
          </li>
          <li>
            <img class="case-two-cont-img" src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/1/case6.jpg" alt="">
            <div class="case-two-text">
              <p class="case-two-text-p1">猛酱酱酒OPPO营销平台推广案例：贵州茅台镇53°酱香型白酒</p>
            <p class="case-two-text-p2">通过深度剖解析白酒行业目标人群，定向投放白酒需求人群</p>
            <p class="case-two-text-p3">猛酱酱酒|浙江新聚</p>
            </div>
          </li>
          <li>
            <img class="case-two-cont-img" src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/1/case5.jpg" alt="">
            <div class="case-two-text">
              <p class="case-two-text-p1">宏迪机械OPPO营销平台推广案例：垃圾回收，变废为宝</p>
            <p class="case-two-text-p2">通过深度剖解析招商加盟行业目标人群，定向投放加盟需求人群…</p>
            <p class="case-two-text-p3">宏迪机械|浙江新聚</p>
            </div>
          </li>

        </ul>
    <el-button @click="closeFootFrom" class="case-linshibtn" type="primary">立即抢占海量客户</el-button>
    </div>
    
    <!-- 第二部分 -->
    <div class="part-two">
      <div class="part-two-top">
        <p class="part-two-top-p1">精准定向活跃用户，多样组合玩法满足用户需求，抢占用户心智，提高转化率，达成营销目标。</p>
        <p class="part-two-top-p2">刷新闻资讯，搜今日大事拥有丰富的流量场景</p>
      </div>
      <div class="part-two-bottom">
        <div class="part-two-bottom-icon">
          <ul class="icon">
            <li @click="isIcon = 0"><img :class="isIcon == 0 ? 'active' : ''"
                src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/1%E6%B5%8F%E8%A7%88%E5%99%A8.png"
                alt=""></li>
            <li @click="isIcon = 1"><img :class="isIcon == 1 ? 'active' : ''"
                src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/2%E8%BD%AF%E4%BB%B6%E5%95%86%E5%BA%97.png"
                alt=""></li>
            <li @click="isIcon = 2"><img :class="isIcon == 2 ? 'active' : ''"
                src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/3%E4%B8%BB%E9%A2%98%E5%95%86%E5%BA%97.png"
                alt=""></li>
            <li @click="isIcon = 3"><img :class="isIcon == 3 ? 'active' : ''"
                src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/4%E5%A4%A9%E6%B0%94.png" alt=""></li>
            <li @click="isIcon = 4"><img :class="isIcon == 4 ? 'active' : ''"
                src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/5%E8%A7%86%E9%A2%91.png" alt=""></li>
            <li @click="isIcon = 5"><img :class="isIcon == 5 ? 'active' : ''"
                src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/6%E5%85%A8%E5%B1%80%E6%90%9C%E7%B4%A2.png"
                alt=""></li>
            <li @click="isIcon = 6"><img :class="isIcon == 6 ? 'active' : ''"
                src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/7%E6%89%8B%E6%9C%BA%E9%94%81%E5%B1%8F.png"
                alt=""></li>
          </ul>
        </div>
        <div class="part-two-bottom-text animate__animated animate__zoomIn " v-show="isIcon == 0">
          <div class="part-two-bottom-text-top">
            <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/1%E6%B5%8F%E8%A7%88%E5%99%A8.png"
              alt="">
            <p class="part-two-bottom-text-top-p1">浏览器</p>
            <p class="part-two-bottom-text-top-p2">搜今日大事拥有丰富的流量场景</p>
          </div>
          <div class="part-two-bottom-text-bottom">
            <p>
              oppo浏览器经过官方权威认证，为广大手机用户提供一个安全稳定便捷高效的上网环境，超强广告拦截，让你上网更安全，安全省流。支持主流各大搜索引擎，内置海量新闻头条免费小说影视网址导航，提供丰富的内容资源和强大搜索能力，带给您全新的上网搜索体验。
            </p>
          </div>
        </div>
        <div class="part-two-bottom-text animate__animated animate__zoomIn" v-show="isIcon == 1">
          <div class="part-two-bottom-text-top">
            <img
              src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/2%E8%BD%AF%E4%BB%B6%E5%95%86%E5%BA%97.png"
              alt="">
            <p class="part-two-bottom-text-top-p1">软件商店</p>
            <p class="part-two-bottom-text-top-p2">搜今日大事拥有丰富的流量场景</p>
          </div>
          <div class="part-two-bottom-text-bottom">
            <p>软商店是官方、正件版、安全的APP下载渠道，拥有海量资源，所有资源都通过机器检测、人工亲测，为用户提供安全可靠的应用、游戏资源下载及管理功能。</p>
          </div>
        </div>
        <div class="part-two-bottom-text animate__animated animate__zoomIn" v-show="isIcon == 2">
          <div class="part-two-bottom-text-top">
            <img
              src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/3%E4%B8%BB%E9%A2%98%E5%95%86%E5%BA%97.png"
              alt="">
            <p class="part-two-bottom-text-top-p1">主题商店</p>
            <p class="part-two-bottom-text-top-p2">搜今日大事拥有丰富的流量场景</p>
          </div>
          <div class="part-two-bottom-text-bottom">
            <p>主题商店是一款桌面增强软件。拥有上千款百变主题，上万种个性搭配，能够独创个性主题、百变锁屏与自由桌面，让你的手机与众不同，不到2M的安装包，让手机更轻便，操作更快捷。</p>
          </div>
        </div>
        <div class="part-two-bottom-text animate__animated animate__zoomIn" v-show="isIcon == 3">
          <div class="part-two-bottom-text-top">
            <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/4%E5%A4%A9%E6%B0%94.png" alt="">
            <p class="part-two-bottom-text-top-p1">天气</p>
            <p class="part-two-bottom-text-top-p2">沉浸式体验轻松应对恶劣天气</p>
          </div>
          <div class="part-two-bottom-text-bottom">
            <p>天气数据依托中国气象局提供专业、科学、准确、及时的预报信息。功能全面升级，界面更美观，全新视觉特效。实时天气预警信息让你从容应对各类突发天气状况。未来十五天天气预报为你的出行早做计划。</p>
          </div>
        </div>
        <div class="part-two-bottom-text animate__animated animate__zoomIn" v-show="isIcon == 4">
          <div class="part-two-bottom-text-top">
            <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/5%E8%A7%86%E9%A2%91.png" alt="">
            <p class="part-two-bottom-text-top-p1">视频</p>
            <p class="part-two-bottom-text-top-p2">触达泛娱乐化人群厂商自有娱乐场景</p>
          </div>
          <div class="part-two-bottom-text-bottom">
            <p>OPPO视频播放器旨在为用户提供海量的视频内容及智能化的用户体验。视频内容丰富多样类型看点多，涵盖电影、电视剧、直播等最新最热分类和内容，人性化功能设计优化用户使用感受。</p>
          </div>
        </div>
        <div class="part-two-bottom-text animate__animated animate__zoomIn" v-show="isIcon == 5">
          <div class="part-two-bottom-text-top">
            <img
              src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/6%E5%85%A8%E5%B1%80%E6%90%9C%E7%B4%A2.png"
              alt="">
            <p class="part-two-bottom-text-top-p1">全局搜索</p>
            <p class="part-two-bottom-text-top-p2">用户高频使用高效完成用户端信息检索</p>
          </div>
          <div class="part-two-bottom-text-bottom">
            <p> 作为一款简单、实用、干净的文件搜索工具，帮助用户整理好自己的文件，没有任何的广告插件，不会影响用户的使用。 </p>
          </div>
        </div>
        <div class="part-two-bottom-text animate__animated animate__zoomIn" v-show="isIcon == 6">
          <div class="part-two-bottom-text-top">
            <img
              src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/7%E6%89%8B%E6%9C%BA%E9%94%81%E5%B1%8F.png"
              alt="">
            <p class="part-two-bottom-text-top-p1">手机锁屏</p>
            <p class="part-two-bottom-text-top-p2">利用厂商优势抢占用户心智</p>
          </div>
          <div class="part-two-bottom-text-bottom">
            <p>拥有全屏浏览畅享的无死角视觉盛宴，做到海量精美图片随心换，让你的手机锁屏不再枯燥。还能进行个性化智能推荐，定制专属的个性化锁屏。</p>
          </div>
        </div>
      </div>
      <el-button @click="changeFootFrom" class="linshibtn22" type="primary">立即抢占海量客户</el-button>
    </div>
    <!-- 第三部分 -->
    <div class="part-three">
      <div class="part-three-top">
        <p class="part-three-top-p1">经济复苏背景下 企业如何抓住机遇勇攀</p>
        <p class="part-three-top-p2">受疫情冲击，企业正面临着各种获客难题</p>
      </div>
      <div class="part-three-bottom">
        <ul>
          <li class="part-three-bottom-list">
            <div class="part-three-bottom-img"><img
                src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E5%9B%BE1.jpg" alt=""></div>
            <div class="part-three-bottom-p">
              <p class="part-three-bottom-p1">多元化资源矩阵</p>
              <p class="part-three-bottom-p2">精准定向活跃用户，多样组合玩法满足用户需求，抢占用户心智，提高转化率，达成营销目标。</p>
            </div>
          </li>
          <li class="part-three-bottom-list">
            <div class="part-three-bottom-img"><img
                src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E5%9B%BE2.jpg" alt=""></div>
            <div class="part-three-bottom-p">
              <p class="part-three-bottom-p1">智慧商业产品布局</p>
              <p class="part-three-bottom-p2">背靠大厂，一站式实现大范围覆盖，依托互联网技术，承接丰富的组合推广样式，加强曝光。</p>
            </div>
          </li>
          <li class="part-three-bottom-list">
            <div class="part-three-bottom-img"><img
                src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E5%9B%BE3.jpg" alt=""></div>
            <div class="part-three-bottom-p">
              <p class="part-three-bottom-p1">以用户体验为准</p>
              <p class="part-three-bottom-p2">从用户角度出发，贴近实际生活，将广告自然融入到资讯动态中，让广告更原生，触达更有效。</p>
            </div>
          </li>
          <li class="part-three-bottom-list">
            <div class="part-three-bottom-img"><img
                src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E5%9B%BE4.jpg" alt=""></div>
            <div class="part-three-bottom-p">
              <p class="part-three-bottom-p1">详尽的投放指南</p>
              <p class="part-three-bottom-p2">完整透明的投放手册与营销学堂，费用明晰，一目了然，做到点对点教学，新手也能轻松get</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <el-button @click="changeFootFrom" class="part-three-btn" type="primary">获取您的企业专属解决方案</el-button>
    <!-- 第四部分 -->
    <div class="part-four">
      <div class="part-four-top">
        <p class="part-four-top-p1">为您的品牌提高知名度 促进销量领跑市场</p>
        <p class="part-four-top-p2">根据关节词匹配流量，精准投放快速曝光</p>
      </div>
      <div class="part-four-img" v-show="isFour == 0">
        <img class="part-four-img-img1"
          src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E5%A4%A7%E5%9B%BE1.png" alt="">
        <img class="part-four-img-img2 animate__animated animate__zoomIn"
          src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E5%B0%8F%E5%9B%BE1.png" alt="">
      </div>
      <div class="part-four-img" v-show="isFour == 1">
        <img class="part-four-img-img1"
          src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E5%A4%A7%E5%9B%BE2.png" alt="">
        <img class="part-four-img-img2 animate__animated animate__zoomIn"
          src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E5%B0%8F%E5%9B%BE2.png" alt="">
      </div>
      <div class="part-four-img" v-show="isFour == 2">
        <img class="part-four-img-img1"
          src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E5%A4%A7%E5%9B%BE3.png" alt="">
        <img class="part-four-img-img2 animate__animated animate__zoomIn"
          src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E5%B0%8F%E5%9B%BE3.png" alt="">
      </div>
      <div class="part-four-text">
        <ul>
          <li class="part-four-text-child" @click="isFour = 0" :class="isFour == 0 ? 'active-four' : ''">
            <p class="four-text-p1">公司介绍</p>
            <p class="four-text-p2">客户带来FAST商业增长的一站式融合销售服务平台</p>
          </li>
          <li class="part-four-text-child" @click="isFour = 1" :class="isFour == 1 ? 'active-four' : ''">
            <p class="four-text-p1">营销平台</p>
            <p class="four-text-p2">OPPO软件商店、OPPO浏览器、新闻资讯</p>
          </li>
          <li class="part-four-text-child" @click="isFour = 2" :class="isFour == 2 ? 'active-four' : ''">
            <p class="four-text-p1">营销亮点</p>
            <p class="four-text-p2">智慧型商业产品布局 全方位服务体系 多元化的资源矩阵</p>
          </li>
        </ul>
      </div>
      <el-button @click="changeFootFrom" class="linshibtn" type="primary">线上获取1v1专业解决方案</el-button>
    </div>
    <!-- 第五部分 -->
    <div class="part-five">
      <div class="part-five-top">
        <p class="part-five-top-p1">即刻获取专属1v1服务</p>
        <p class="part-five-top-p2">现在预约专属销售顾问立即为您提供服务</p>
      </div>
      <el-form :model="fromData" class="part-five-from" label-position="left" label-width="50px" ref="fromData"
        :rules=rules>
        <el-form-item label="" prop="user" class="from-child">
          <el-input v-model.trim="fromData.user" placeholder="姓名"></el-input>
        </el-form-item>
        <el-form-item label="" prop="phone" class="from-child">
          <el-input v-model.trim="fromData.phone" placeholder="手机"></el-input>
        </el-form-item>
        <el-form-item label="" prop="name" class="from-child">
          <el-input v-model.trim="fromData.name" placeholder="公司名称"></el-input>
        </el-form-item>
        <el-form-item label="" prop="business" class="from-child">
          <el-input v-model.trim="fromData.business" placeholder="行业分类（不清楚可不填）"></el-input>
        </el-form-item>
        <el-button class="linshibtn" @click="submitFromData('fromData')" type="primary">立即咨询</el-button>
      </el-form>
    </div>
    <!-- 第六部分 -->
    <!-- <div class="part-six">
      <div class="part-six-text">
        <p class="part-six-p1">联系我们：</p>
      <p class="part-six-p2">售前电话：15538278112</p>
      <p class="part-six-p3">通过OPPO营销获取更多客户</p>
      <p class="part-six-p4"><a href="http://beian.miit.gov.cn/">浙ICP备2023011741号</a></p>
      </div>
    </div> -->
    <!-- 弹起的表单 -->
    <!-- <zhe></zhe> -->
    <div class="from-foot " v-show="isFootFrom">
       <div class="from-foot-from animate__animated animate__fadeInUp" >
        <h3 @click="closeFootFrom">×</h3>
        <el-form :model="fromData" class="part-foot-from" label-position="left" label-width="50px" ref="fromData"
        :rules=rules>
        <el-form-item label="" prop="user" class="from-child">
          <el-input v-model.trim="fromData.user" placeholder="姓名"></el-input>
        </el-form-item>
        <el-form-item label="" prop="phone" class="from-child">
          <el-input v-model.trim="fromData.phone" placeholder="手机"></el-input>
        </el-form-item>
        <el-form-item label="" prop="name" class="from-child">
          <el-input v-model.trim="fromData.name" placeholder="公司名称"></el-input>
        </el-form-item>
        <el-form-item label="" prop="business" class="from-child">
          <el-input v-model.trim="fromData.business" placeholder="行业分类（不清楚可不填）"></el-input>
        </el-form-item>
        <el-button class="linshibtn" @click="submitFromData('fromData')" type="primary">立即咨询</el-button>
      </el-form>
       </div>
    </div>
    <!-- 表单提交后的提示信息 -->
    <div class="subSuccess " v-show="isSuss">
      <div class="message animate__animated animate__fadeInDown">
        <h2 @click="changeIsSuss">×</h2>
        <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/icon-success.png" alt="">
        <div class="mss-text">
          <h3 class="mess-text-p">信息提交已成功</h3>
          <p>您的专属顾问会在一个工作日内联系您，请您保持电话畅通</p>
          <p>信息提交已成功</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/swiper-bundle.css'
import { submitData } from '@/api/http'
// import { regionData, CodeToText } from 'element-china-area-data'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  data() {
    return {
      isSuss:false,
      isIcon: 0,
      iscaseTwo:0,
      isFour: 0,
      isRouter:0,
      isFootFrom:false,
      fromData: {
        user: '',
        phone: '',
        name: '',
        business:""
      },
      swiperOption: {
        //显示分页
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        //切换模式  横屏或者竖屏
        // direction : 'vertical',
        //设置自动播放速度
        autoplay: {
          disableOnInteraction: true,
          delay: 10000000000
        },
        //开启无限循环
        loop: true,
        //设置点击箭头
        paginationClickable: false,
        prevButton: '.swiper-button-prev',
        nextButton: '.swiper-button-next',
        //设置同屏显示的数量，默认为1，使用auto是随意的意思。
        slidesPerView: 1,
        //开启鼠标滚轮控制Swiper切换。可设置鼠标选项，或true使用默认值。
        mousewheel: true,
        //默认为false，普通模式：slide滑动时只滑动一格，并自动贴合wrapper，设置为true则变为free模式，slide会根据惯性滑动可能不止一格且不会贴合。
        // freeMode:true
      },
      
      // 表单正则
      rules: {
        user: [
          { required: true, message: "请输入您的姓名", trigger: "blur" }
        ],
        phone: [
          { required: true, message: "请输入您的联系方式", trigger: "blur" },
          { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: "必须输入正确的电话号码", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请输入您的公司名称", trigger: "blur" }
        ]
      },
    }
  },
  becreated:{
  
  },
  components: {
    swiper,
    swiperSlide
  },
  methods: {
    // 轮播图左右两个按钮
    swiperNext() {
      this.$refs.interSwiper.swiper.slideNext()
    },
    swiperPrev() {
      this.$refs.interSwiper.swiper.slidePrev()
    },
    submitFromData(fromData) {
      this.$refs[fromData].validate(async (valid) => {
        if (valid) {
          // 当数据有效时
          console.log(this.fromData);
          fromData=this.fromData
          let res = submitData(fromData).then(res => {
            // console.log('res',res.data);
          })
          // 弹起消息提示框
            this.isSuss=true
            // 清空表单数据
            this.$refs.fromData.resetFields()
            // 关闭表单
            this.isFootFrom=false

        }else{
            this.$message.error('提交失败')
        }
      }
      )
    
    },
    changeFootFrom(){
      this.isFootFrom=!this.isFootFrom
    },
    closeFootFrom(){
      this.isFootFrom=!this.isFootFrom
    },
    changeIsSuss(){
      this.isSuss=!this.isSuss
    }
  },

  
}
</script>

<style lang="less">
.body {
  width: 100vw;
  margin: 0 auto;
}

.swiper {
  width: 100vw;
  height: 40vh;
  margin: 0 auto;
  margin-top: 5vw;

  img {
    width: 100%;
    height: 100%;
  }
}
// 第二部分
.case-two{
  margin-top: 10vw;
  width: 100vw;
  // height: 150vw;
  // background-color: aqua;
  .case-linshibtn{
    margin-top: 3vh;
  }
  .part-two-top {
    width: 80%;
    margin: 0 auto;
    height: 20vw;

    .part-two-top-p1 {
      color: #000;
      font-size: 18px;
      font-weight: 600;
    }

    .part-two-top-p2 {
      margin-top: 5vw;
      color: #919191;
      font-size: 13px;
    }
  }
  .case-active{
    color: #fff;
    background-color: rgb(64, 158, 255);

  }
 .case-two-tab{
    width: 100vw;
    height: 25vw;
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap;
    list-style: none;
    li{
      width: 30vw;
      height: 10vw;
      line-height: 10vw;
      border: 1px solid #ccc;
      transition: all .4s linear;
    }
  }
  .case-two-cont{
    margin-top: 5vw;
    li{
      display: flex;
    justify-content: space-between;
    }
    .case-two-cont-img{
        width: 50vw;
        height: 50vw;
      }
      .case-two-text{
        width: 50vw;
        height: 35vw;
        .case-two-text-p1{
          margin-top: 5vw;
          font-size: 15px;
          font-weight: 600;
          text-align: left;

        }
        .case-two-text-p2{
          text-align: left;
          color: #747474;
          margin-top: 5vw;
          font-size: 15px;
        }
        .case-two-text-p3{
          text-align: left;
          color: #c0bebe;
          margin-top: 2vw;
          font-size: 12px;
        }
      }
  }
}
// 第二部分
.part-two {
  margin-top: 10vw;
  width: 100vw;
  height: 100vw;
  .linshibtn {
    // margin-top: 5vw;
    width: 188px;
  }

  // background-color: greenyellow;
  .part-two-top {
    width: 80%;
    margin: 0 auto;
    height: 20vw;

    .part-two-top-p1 {
      color: #000;
      font-size: 18px;
      font-weight: 600;
    }

    .part-two-top-p2 {
      margin-top: 5vw;
      color: #919191;
      font-size: 13px;
    }
  }

  .part-two-bottom {
    margin-top: 15vw;
    width: 100%;
    height: 90vw;
    line-height: 8vw;

    // background-color: red;
    .part-two-bottom-icon {
      width: 100%;
      height: 20vw;

      .icon {
        // width: 100%;
        height: 20vw;
        display: flex;
        // justify-content: space-between;
        overflow-x: auto;
        // overflow-y: hidden;
        
        li {
          list-style: none;
          margin-right: 20px;
        }

        .active {
          // border-radius: 15px;
          // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9)
            /*blur值为5px*/
          // transform: scale(1.1);
          width: 16vw;
          height: 84%;
        }

        img {
          width: 15vw;
          height: 80%;
        }
      }
    }

    .part-two-bottom-text {
      width: 90vw;
      height: 60vw;
      // background-color: green;
      margin: 0 auto;

      .part-two-bottom-text-top {
        margin-top: 10vw;
        width: 100%;
        height: 20vw;
        text-align: left;

        img {
          float: left;
          margin-left: 5vw;
          margin-right: 5vw;
          width: 18vw;
          height: 90%;
        }

        .part-two-bottom-text-top-p1 {
          color: #000;
          font-weight: 600;
        }

        .part-two-bottom-text-top-p2 {
          color: #8f8f8f;
        }

      }

      .part-two-bottom-text-bottom {
        width: 100%;
        height: 60vw;
        text-align: left;
        line-height: 7vw;
        margin-top: 5vw;

        p {
          color: #8f8f8f;

        }
      }
    }
  }
}
.part-three-btn{
    margin-top: 3vh;
  }
// 第三部分
.part-three {
  margin-top: 60vw;
  width: 100vw;
  height: 150vw;


  .part-three-top {
    width: 80%;
    margin: 0 auto;
    height: 20vw;

    .part-three-top-p1 {
      color: #000;
      font-size: 18px;
      font-weight: 600;
    }

    .part-three-top-p2 {
      margin-top: 5vw;
      color: #8a8a8a;
      font-size: 13px;
    }
  }

  .part-three-bottom {
    width: 100vw;
    height: 60vw;
    margin-top: 15vw;

    .part-three-bottom-list {
      display: flex;
      justify-content: space-around;
      margin-top: 5vw;
      width: 100%;
      height: 25vw;

      .part-three-bottom-img {
        width: 30%;
        height: 100%;
        background-color: #fff;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .part-three-bottom-p {
        width: 60%;
        height: 100%;
        text-align: left;

        .part-three-bottom-p1 {
          color: #000;
          font-weight: 600;
        }

        .part-three-bottom-p2 {
          color: #7a7878;
          margin-top: 3vw;
          font-weight: 400;
          font-size: 13px;
        }
      }
    }
  }

}

.linshibtn22 {
  margin-top: 20vw;
}

// 第四部分
.part-four {
  margin-top: 10vw;
  width: 100vw;
  height: 195vw;
  background-color: #1a1a1a;

  .part-four-top {
    width: 80%;
    margin: 0 auto;
    height: 20vw;
    // margin-top: 10vw;
    padding-top: 10vw;

    .part-four-top-p1 {
      color: #fff;
      font-size: 18px;
      font-weight: 600;
    }

    .part-four-top-p2 {
      margin-top: 5vw;
      color: #ffffff;
      font-size: 13px;
    }
  }

  .part-four-img {
    width: 50vw;
    height: 60vw;
    margin: 0 auto;
    margin-top: 10vw;
    position: relative;

    .part-four-img-img1 {
      width: 100%;
      height: 100%;
    }

    .part-four-img-img2 {
      width: 60vw;
      height: 30vw;
      position: absolute;
      left: -5vw;
      top: 15vw;
    }
  }

  .part-four-text {
    width: 96vw;
    height: 70vw;
    margin: 0 auto;
    margin-top: 10vw;

    ul {

      display: flex;
      justify-content: space-around;
      list-style: none;

      .part-four-text-child {
        width: 30vw;
        height: 50vw;
        background-color: #272a39;
        border: 1px solid #444;
        border-radius: 10px;
        color: #fff;

        .four-text-p1 {
          margin-top: 5vw;
        }

        .four-text-p2 {
          margin-top: 8vw;
        }
      }

      .active-four {
        background-color: #4581fb;
        transition: all 1s;
      }
    }

    // .part-four-text-child:hover{
    //   background-color:#4581fb;
    //   transition: all 1s;
    // }

  }

  .linshibtn {
    margin-top: -10vw;
  }
}

// 第五部分
.part-five {
  width: 100vw;
  height: 115vw;

  .part-five-top {
    width: 80%;
    margin: 0 auto;
    height: 20vw;
    // margin-top: 10vw;
    padding-top: 10vw;

    .part-five-top-p1 {
      color: #000;
      font-size: 18px;
      font-weight: 600;
    }

    .part-five-top-p2 {
      margin-top: 5vw;
      color: #b3aeae;
      font-size: 13px;
    }
  }

  .part-five-from {
    width: 90vw;
    height: 60vw;
    margin: 0 auto;
    text-align: center;
    .from-child{
      width: 80vw;
    }
  }

  .linshibtn {
    margin-top: -1vw;
    width: 188px;
  }
}
.part-six{
  width: 100vw;
  height: 40vw;
  background-color: #1a1a1a;
  color: #fff;
  text-align: left;
  padding-top: 8vw;
.part-six-text{
  width: 60vw;
  height: 50vw;
  line-height: 8vw;
  margin-left: 16vw;
  font-size: 16px;
  .part-six-p4{
    text-align: center;
    a{
      margin-top: 5vw;
      text-decoration: none;
      color: #fff;
    }
}
}
}
// <!-- 弹起的表单 -->
.from-foot{
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
    background: rgba(0,0,0,0.3);

 
  .from-foot-from{
    // text-align: right;
   h3{
    text-align: right;
   }
    width: 100vw;
    height: 100vw;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    .part-foot-from{
      margin-top: 10vw;
      .from-child{
      width: 80vw;
    }
    }
  
  }
}
// 表单提交成功后的提示信息
.subSuccess{
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  .message{
    width: 90vw;
    height: 70vw;
    background-color: #fff;
    margin: 20vh auto;
    border-radius: 2vw;
    h2{
      margin-left: 80vw;
      color:#6d6c6c;
    }
    img{
      width: 52px;
      height: 52px;
    }
    .mss-text{
      width: 55vw;
      height: 40vw;
      margin: 5vw auto;
      .mess-text-p{
          margin-bottom: 5vw;
      }
    }
  }
}
</style>